import * as angular from 'angular';
import 'signalr';
import {SpinnerService} from './SpinnerModule';
import {IQService, IRootScopeService, ITimeoutService, IWindowService} from "angular";

export type StateChangeCallback = (change: SignalR.StateChanged) => void;

export class HubProxyFactory {
    constructor(private $q: IQService,
                private $rootScope: IRootScopeService,
                private $timeout: ITimeoutService,
                private $window: IWindowService,
                private $spinner: SpinnerService) {
    }

    create(hubName: string, logging: boolean) {
        return new HubProxy(this.$q, this.$rootScope, this.$timeout, this.$window, this.$spinner, hubName, logging);
    }
}

export class HubProxy {
    connection: SignalR.Hub.Connection;
    private proxy: SignalR.Hub.Proxy;
    private autoReconnect = true;
    private stateChangeObservers: StateChangeCallback[] = [];

    constructor(private $q: IQService,
                private $rootScope: IRootScopeService,
                private $timeout: ITimeoutService,
                private $window: IWindowService,
                private $spinner: SpinnerService,
                private hubName: string,
                logging: boolean) {
        var url = $window.location.hostname === 'localhost' ? 'http://localhost:8081/signalr' :
            $window.apiBase.replace('/api/', '') + '/signalr';
        this.connection = $.hubConnection(url, {
            logging: logging,
            useDefaultPath: false
        });
        this.connection.stateChanged((change: SignalR.StateChanged) => {
            this.$rootScope.$applyAsync(() => {
                this.stateChanged(change);
            });
        });

        this.connection.error((error: SignalR.ConnectionError) => {
            console.error(`hubProxy ${hubName}`, error);
            if (error.context && error.context.status === 401) {
                this.autoReconnect = false;
                this.connection.stop();
            }
        });
        this.proxy = this.connection.createHubProxy(hubName);

    }

    /**
     * Returns a $q that should be .resolve()'d when the subscription should be removed
     * @param eventName
     * @param callback
     */
    on(eventName: string, callback: (...msg: any[]) => void): angular.IDeferred<void> {
        // use the callback as the identity, instead of the wrapped function
        let callbackIdentity = callback;
        let wrappedCallback = (...params: any[]) => {
            this.$rootScope.$applyAsync(() => {
                if (callback) {
                    callback.apply(this.proxy, params);
                }
            });
        };
        let defer = this.$q.defer<void>();
        defer.promise.then(() => {
            console.debug('resolved', eventName, callback);
            // @ts-ignore TypeDef is , third param is valid
            this.proxy.off(eventName, wrappedCallback, callbackIdentity)
        });

        // @ts-ignore TypeDef is wrong, third param is valid
        this.proxy.on(eventName, wrappedCallback, callbackIdentity);
        return defer;
    };

    /**
     * not sure the identity is preserved. test this carefully before use
     * @param eventName
     * @param callback
     */
    off(eventName: string, callback: (...msg: any[]) => void) {
        this.proxy.off(eventName,
            (...params) => {
                this.$rootScope.$applyAsync(() => {
                    if (callback) {
                        callback.apply(this.proxy, params);
                    }
                });
            });
    };

    invoke<T>(methodName: string, ...args: any[]) {
        var allArgs = arguments;
        var q = this.$q.when<T>(this.proxy.invoke.apply(this.proxy, allArgs));
        return q;
    };

    start(): ng.IPromise<any> {
        // initiate start via a defer 
        // may be called inside a constructor which is already inside a $digest loop
        var q = this.$q.defer();
        this.$rootScope.$applyAsync(() => q.resolve(this.connection.start()));
        return q.promise;
    };

    stop() {
        console.debug('HubProxyModule.stop()')
        this.autoReconnect = false;
        this.connection.stop();
    }

    onStateChanged(callback: StateChangeCallback): angular.IDeferred<void> {
        this.stateChangeObservers.push(callback);
        let defer = this.$q.defer<void>();
        defer.promise.then(() => {
            this.stateChangeObservers.remove(callback, x => x);
        })
        return defer;
    };

    onError(callback: (error: SignalR.ConnectionError) => any) {
        this.connection.error((error: SignalR.ConnectionError) => {
            this.$rootScope.$applyAsync(() => {
                if (callback) {
                    callback(error);
                }
            });
        });
    };

    onConnected(callback: () => void) {
        return this.onStateChanged(x => {
            if (x.newState == SignalR.ConnectionState.Connected)
                callback();
        });
    };

    get isConnected() {
        return this.connection.state == SignalR.ConnectionState.Connected;
    }

    onReconnected(callback: () => void) {
        this.connection.reconnected(() => {
            this.$rootScope.$applyAsync(() => {
                if (callback) {
                    callback();
                }
            });
        });
    };

    onReconnecting(callback: () => void) {
        this.connection.reconnecting(() => {
            this.$rootScope.$applyAsync(() => {
                if (callback) {
                    callback();
                }
            });
        });
    };

    onDisconnected(callback: () => void) {
        this.connection.disconnected(() => {
            this.$rootScope.$applyAsync(() => {
                if (callback) {
                    callback();
                }
            });
        });
    };

    onConnectionSlow(callback: () => void) {
        this.connection.connectionSlow(() => {
            this.$rootScope.$applyAsync(() => {
                if (callback) {
                    callback();
                }
            });
        });
    };

    private stateChanged(change: SignalR.StateChanged) {
        console.debug(`hubProxy ${this.hubName} stateChanged`, change);
        switch (change.newState) {

            case SignalR.ConnectionState.Connecting:
                this.$spinner.show('Connecting', false);
                break;
            case SignalR.ConnectionState.Connected:
                this.autoReconnect = true;
                this.$spinner.hide();
                break;
            case SignalR.ConnectionState.Reconnecting:
                this.$spinner.show('Reconnecting', false);
                break;
            case SignalR.ConnectionState.Disconnected:
                if (this.autoReconnect) {
                    this.$spinner.show('Disconnected', false);
                    this.$timeout(() => this.connection.start(), 5000);
                } else {
                    this.$spinner.hide();
                }
                break;
        }

        this.stateChangeObservers.forEach(observer => {
            try {
                observer(change);
            } catch (e) {
                console.error(e);
            }
        })
    }
}

export default angular.module('services.hubproxy', [])
    .service('hubProxyFactory', HubProxyFactory)
    .name;
