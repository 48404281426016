import * as angular from 'angular';
import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {UserDto} from "../model/UserDto";
import {Ng1Controller, TargetState, Transition, StateService} from "@uirouter/angularjs";
import {StudentTypeDto} from "../model/StudentTypeDto";
import {DocumentDto} from "../model/DocumentDto";
import {ConfigService} from "../modules/ConfigModule";

export default class ProfileController implements Ng1Controller {
    private user: UserDto;
    private studentTypes: StudentTypeDto[] = [];

    private d1: angular.IDeferred<void>;
    private d2: angular.IDeferred<void>;
    private d3: angular.IDeferred<void>;
    private documents: DocumentDto[] = [];
    private d4: angular.IDeferred<void>;
    private d5: angular.IDeferred<void>;
    private signature: string;
    private signatureNew: string;
    private signatureEditing: boolean;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        this.d1.resolve();
        this.d2.resolve();
        this.d4.resolve();
        this.d5.resolve();
        this.d3.resolve();
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.$stateParams = Object.assign({}, this.$stateParams, newValues);
    }

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $config: ConfigService,
        private $state: StateService,
        private $stateParams,
    ) {

        this.d1 = $dataHub.onUserUpdated(e => {
            this.user = e;
        });
        this.d2 = $dataHub.bindStudentTypeUpdated(() => this.studentTypes);
        this.d4 = $dataHub.bindDocumentUpdated(() => this.documents)
        this.d5 = $dataHub.bindDocumentRemoved(() => this.documents)
        this.d3 = $dataHub.onConnected(this.refresh);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.studentTypeList();
        this.$spinner.show();
        this.$dataHub.userGet(this.$config.userInfo.id)
            .finally(() => this.$spinner.hide());
        // this.$dataHub.documentUserSubscribe(this.$config.userInfo.id);
        this.signatureRefresh();
    }

    get currentTab() {
        return this.$stateParams.tab;
    }
    set currentTab(value) {
        if(this.$stateParams.tab === value) return;
        this.$state.go('.', {tab:value})
    }

    signatureSave = () => {
        this.$dataHub.userSignatureSelfUpdate(this.signatureNew)
            .then(() => {
                this.signatureRefresh();
                this.signatureEditing=false;
            })
    }

    signatureRefresh = () => {
        this.signature = null;
        this.$dataHub.userSignatureSelfGet()
            .then(r => this.signature = r)
            .catch(this.$dataHub.defaultErrorHandler);
    }


};
