import {DataHubService} from "../modules/DataHubModule";
import {ITimeoutService} from "angular";
import {VirtualClassParticipantDto} from "../model/VirtualClassParticipantDto";
import {VirtualClassParticipantMessageDto} from "../model/VirtualClassParticipantMessageDto";

export class VirtualClassMessageController {
    private messages: VirtualClassParticipantMessageDto[] = [];
    private chatContainer: JQuery<HTMLDivElement>;
    private d1: angular.IDeferred<void>;
    private d2: angular.IDeferred<void>;

    constructor(private $uibModalInstance: any,
                private $timeout: ITimeoutService,
                private $dataHub: DataHubService,
                private participant: VirtualClassParticipantDto,
                private readOnly: boolean) {

        this.d1 = this.$dataHub.onVirtualClassParticipantMessageUpdated(msg => {
            if (msg.participantId != participant.id) return;
            this.messages.merge(msg, x => x.id);
            $timeout(() => {
                if(!this.chatContainer) return;
                let e = this.chatContainer[0];
                if (!e) return;
                e.scrollTop = e.scrollHeight;
            });
        });
        this.d2 = this.$dataHub.onConnected(this.refresh);
        this.refresh()

        // unbind listeners when modal closes
        this.$uibModalInstance.closed.then(() => {
            this.d1.resolve();
            this.d2.resolve();
        });
    }

    refresh = () => {
        this.$dataHub.virtualClassParticipantMessageSubscribe(this.participant.id);
    };

    done = () => {
        this.$uibModalInstance.close('1');
    };

    send = (msg) => {
        if(this.readOnly) return;
        let dto = new VirtualClassParticipantMessageDto();
        dto.participantId = this.participant.id;
        dto.message = msg;
        this.$dataHub.virtualClassParticipantMessageSubmit(dto)
            .catch(this.$dataHub.defaultErrorHandler);
        this.markRead();
    };

    markRead = () => {
        if(this.readOnly) return;
        this.messages.filter(x => !x.read).forEach(msg => {
            this.$dataHub.virtualClassParticipantMessageRead(msg.id);
        })
    };


}
