import * as angular from 'angular';
import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {UserDto} from "../model/UserDto";
import {Ng1Controller, TargetState, Transition, StateService} from "@uirouter/angularjs";
import {StudentTypeDto} from "../model/StudentTypeDto";
import {DocumentDto} from "../model/DocumentDto";

export default class ViewController implements Ng1Controller {
    private user: UserDto;
    private studentTypes: StudentTypeDto[] = [];

    private d1: angular.IDeferred<void>;
    private d2: angular.IDeferred<void>;
    private d3: angular.IDeferred<void>;
    private documents: DocumentDto[] = [];
    private d4: angular.IDeferred<void>;
    private d5: angular.IDeferred<void>;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        this.d1.resolve();
        this.d2.resolve();
        this.d4.resolve();
        this.d5.resolve();
        this.d3.resolve();
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.$stateParams = Object.assign({}, this.$stateParams, newValues);
    }

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $state: StateService,
        private $stateParams,
    ) {

        this.d1 = $dataHub.onUserUpdated(e => {
            this.user = e;
        });
        this.d2 = $dataHub.bindStudentTypeUpdated(() => this.studentTypes);
        this.d4 = $dataHub.bindDocumentUpdated(() => this.documents)
        this.d5 = $dataHub.bindDocumentRemoved(() => this.documents)
        this.d3 = $dataHub.onConnected(this.refresh);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        console.debug('refresh', this.$stateParams)
        this.$dataHub.studentTypeList();
        this.$spinner.show();
        this.$dataHub.userGet(this.$stateParams.id)
            .finally(() => this.$spinner.hide());
        this.$dataHub.documentUserSubscribe(this.$stateParams.id);
    }


    resetPassword = () => {
        this.$dataHub.server.userPasswordReset(this.user.id)
            .then(r => alert('Temporary Password: ' + r + '\n\nUser will be required to change password when logging in.'))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    delete = (document: DocumentDto) => {
        if (!confirm('Delete ' + document.fileName + '?')) return;

        this.$dataHub.documentDelete(document)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    archive = (document: DocumentDto) => {
        this.$dataHub.documentArchive(document.id)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    get currentTab() {
        return this.$stateParams.tab;
    }
    set currentTab(value) {
        if(this.$stateParams.tab === value) return;
        this.$state.go('.', {tab:value})
    }

};
