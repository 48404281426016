import {IDeferred} from "angular";

/**
 * Use to store disposer/finalizer/IDeferred/etc to be cleaned up when view is exited
 */
export class DisposeBag extends Array<IDeferred<void>> {

    /**
     * Call when exiting view. All items will be resolved/finalized.
     */
    finalize = () => {
        console.debug('finalize', this.length, 'items');
        while (true) {
            let d = this.pop();
            if (!d) break;
            d.resolve();
        }
        console.debug('finalize done');
    }
}